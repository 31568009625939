<template>
  <div class="import-container">
    <import-template
      :uploadUrl="uploadUrl"
      :downloadUrl="downloadUrl"
      :uploadLabel="uploadLabel"
      >
      <div slot="remark">
        <div class="remark">
          <p>请在excel中按以上顺序填写数据</p>
          <p>最大支持10MB的excel文件，超过请拆分导入</p>
        </div>
      </div>
    </import-template>
  </div>
</template>
<script>
import { ImportTemplate } from 'components/bussiness/index'
export default {
  components: {
    ImportTemplate
  },
  data () {
    return {
      uploadUrl: '',
      downloadUrl: '',
      uploadLabel: '扩展数据'
    }
  },
  created () {
    this.$setBreadcrumb('导入')
    this.uploadLabel = this.$route.query.text
    this.downloadUrl = this.$route.query.downloadUrl
    this.uploadUrl = this.$route.query.uploadUrl
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
  .remark{
    p {
      font-style: italic;
    }
  }
</style>
