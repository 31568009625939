var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c(
        "import-template",
        {
          attrs: {
            uploadUrl: _vm.uploadUrl,
            downloadUrl: _vm.downloadUrl,
            uploadLabel: _vm.uploadLabel,
          },
        },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _c("div", { staticClass: "remark" }, [
              _c("p", [_vm._v("请在excel中按以上顺序填写数据")]),
              _c("p", [_vm._v("最大支持10MB的excel文件，超过请拆分导入")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }